import {BrowserRouter, Route, Routes} from "react-router-dom";
import {useWebApp} from "@vkruglikov/react-telegram-web-app";
import React, {useEffect} from "react";
import {THEME, TonConnect, TonConnectUIProvider} from "@tonconnect/ui-react";
import {ContestMainPage} from "./modules/contest/pages";

function App() {
  const connector = new TonConnect({
    manifestUrl: "https://cdn.joincommunity.xyz/assets/manifest.json",
    walletsListSource: "https://raw.githubusercontent.com/ton-blockchain/wallets-list/main/wallets-v2.json"
  });

  const WebApp = useWebApp();

  useEffect(() => {
    if (!WebApp.isExpanded) WebApp.expand()
    WebApp.setHeaderColor('#ffffff')
  }, [WebApp])

  return (
    <TonConnectUIProvider
      connector={connector}
      uiPreferences={{ theme: THEME.LIGHT }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="" element={<ContestMainPage/>}/>
        </Routes>
      </BrowserRouter>
    </TonConnectUIProvider>
  )
}

export default App;
