import {ReactNode} from 'react'
import {Content} from '../'
import styles from './Page.module.css';

interface PageProps {
  children?: ReactNode
  className?: string
  fullSpace?: boolean
  hasNotch?: boolean
}

export const Page = ({className, children, fullSpace, hasNotch, ...rest}: PageProps) => {
  return (
    <div className={styles.page}>
      <Content>{children}</Content>
    </div>
  )
}
