import {ReactNode} from 'react'
import styles from './Content.module.css';

interface PageProps {
  children?: ReactNode
  className?: string
  fullSpace?: boolean
  hasNotch?: boolean
}

export const Content = ({className, children, fullSpace, hasNotch, ...rest}: PageProps) => {
  return (
    <div className={styles.content}>
      {children}
    </div>
  )
}
