import {Page} from '../../components';
import {SendTransactionRequest, TonConnectButton, useTonConnectUI} from "@tonconnect/ui-react";
import styles from './ContestMainPage.module.css';
import {useWebApp} from "@vkruglikov/react-telegram-web-app";

const defaultTx: SendTransactionRequest = {
  validUntil: Math.floor(Date.now() / 1000) + 600,
  messages: [
    {
      address: 'UQAutnRwzOWwk_BQlYKlWGxEJVMYIgV2bszpXvD6Q7753HJU',
      amount: '5000000',
    },
  ],
};

export function ContestMainPage() {
  const [tonConnectUi] = useTonConnectUI();
  const WebApp = useWebApp();

  return (
    <Page>
      test <br/>

      <TonConnectButton className={styles.ton_button}/>

      <button onClick={() => tonConnectUi.sendTransaction(defaultTx)}>
        Send transaction
      </button>

      <button onClick={() => alert(WebApp.initData)}>
        show init
      </button>
    </Page>
  )
}
